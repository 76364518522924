<script>
  
  import { defineComponent } from 'vue';
  import { put } from '../common/api';
  import {
        MDBModal,
        MDBModalBody,
    } from 'mdb-vue-ui-kit';
  export default defineComponent({
    name: "LoginView",
    components: {
      MDBModal,
      MDBModalBody,
    },
    data() {
      
      let today = new Date();

      return {
        today: today,
        show_modal: false,
        password: '',
        show_password: false,
        selected_transaction: {
          "name": "transaction",
          "amount": 0,
          "date": today.toISOString(),
          "category": "category",
          "description": "description",
        },
      };
    },
    methods: {
        login(password) {
            this.loading = true;

            put('login', { password: password })
                .then(result => {
                    this.loading = false;
                    if (!result.ok) {
                        alert("Login failed");
                    }
                    else {
                      return result.json();
                    }
                })
                .then(data => {
                    if (data.token) {
                      localStorage.setItem('token', data.token);
                      this.$router.push('/today')
                    }
                });

      },
    },
    created() {
    },
    mounted() {
      this.show_modal = true;
    },
  });
  </script>
<template>
  <div>
    <MDBModal
      id="loginModal"
      tabindex="-1"
      labelledby="loginModal"
      v-model="show_modal"
      size="lg"
      staticBackdrop
    >
      <MDBModalBody>
        <p>{{ today }}</p>
        <input
          :type="show_password ? 'text' : 'password'"
          v-model="password"
          @keyup.enter="login(password)"
        />
        <br/>
        <label>
          <input
            type="checkbox"
            v-model="show_password"
          />
          show password
        </label>
      </MDBModalBody>
    </MDBModal>
    <p>logging in</p>
  </div>
</template>
