<script>
 import {
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
    } from 'mdb-vue-ui-kit';
  import 'vue-json-viewer/style.css'
  import JsonViewer from 'vue-json-viewer/ssr';
  import { reactive, defineComponent } from 'vue';
  import MonthNav from '../components/MonthNav.vue';
  import BudgetItemGrid from '../components/BudgetItemGrid.vue';  
  import CategoryGrid from '@/components/CategoryGrid.vue';
  import { checkLogin } from '../common/auth';
  import { get, post } from '../common/api';

  export default defineComponent({
    name: "MonthView",
    components: {
      BudgetItemGrid,
      CategoryGrid,
      MonthNav,
      MDBModal,
      MDBModalBody,
      MDBModalHeader,
      MDBModalTitle,
      JsonViewer,
    },
    props: {
      month: Number,
      year: Number
    },
    data() {

      return {
        expenses: reactive([]),
        savings: reactive([]),
        incomes: reactive([]),
        credit_card_payments: reactive([]),
        loading: true,
        selected_month: this.month - 1,
        selected_year: this.year,
        show_modal: false,
        categories: reactive([]),
        selected_category: reactive({}),
      };
    },
    methods: {
      setSelectedTransaction(transaction) {
        this.selected_transaction = transaction;
      },
      setSelectedCategory(category) {
        this.selected_category = category;
      },
      showDetailView() {
        console.log("show modal")
        this.show_modal = true;
      },
      closeModal() {
        this.show_modal = false;
      },
      transactionEdited(transaction) {
        console.log("transaction edited")
        this.updateTransaction(transaction);
        this.calculateCategoryTotals();
      },
      updateTransaction (transaction) {
        console.log("updating transaction")
        console.log(transaction)
        // if ("v3_type" in transaction) {
        //   if (transaction['v3_type'] == 'investment') {
        //     const index = this.savings.findIndex(saving => saving.date_transaction_id === transaction.date_transaction_id);
        //     if (index !== -1) {
        //       this.savings.splice(index, 1, transaction);
        //     }
        //     else {
        //       this.savings.push(transaction);
        //     }
        //   }
        //   else if (transaction['v3_type'].includes('expense')) {
        //     const index = this.expenses.findIndex(expense => expense.date_transaction_id === transaction.date_transaction_id);
        //     if (index !== -1) {
        //       this.expenses.splice(index, 1, transaction);
        //     }
        //     else {
        //       this.expenses.push(transaction);
        //     }
        //   }
        //   else if (transaction['v3_type'].includes('income')) {
        //     const index = this.incomes.findIndex(income => income.date_transaction_id === transaction.date_transaction_id);
        //     if (index !== -1) {
        //       this.incomes.splice(index, 1, transaction);
        //     }
        //     else {
        //       this.incomes.push(transaction);
        //     }
        //   }
        //   else if (transaction['v3_type'].includes('credit_card_payment')) {
        //     const index = this.credit_card_payments.findIndex(credit_card_payment => credit_card_payment.date_transaction_id === transaction.date_transaction_id);
        //     if (index !== -1) {
        //       this.credit_card_payments.splice(index, 1, transaction);
        //     }
        //     else {
        //       this.credit_card_payments.push(transaction);
        //     }
        //   }
        //   else {
        //     console.log(transaction)
        //   } 
        // }
        // else {
        //   console.log(transaction)
        // }
      },
      calculateCategoryTotals() {
        console.log("calculating category totals")
        let category_totals = {};
        this.expenses.forEach((expense) => {
          if (expense.v3_category in category_totals) {
            category_totals[expense.v3_category] += Number(expense.v3_amount);
          }
          else {
            category_totals[expense.v3_category] = Number(expense.v3_amount);
          }
        });
        let newCategories = [];
        this.categories?.forEach((category) => {
          if (category.name in category_totals) {
            category.spent = Number(category_totals[category.name]);
          }
          else {
            category.spent = 0.0;
          }
          newCategories.push(category);
        });
        this.categories = newCategories;
      },
      getCategories() {
        console.log("getting categories")
        this.loading = true;

        get('categories')
          .then(result => {
            if (result.status == 401) {
              localStorage.removeItem('token');
              this.$router.push('/login');
              throw new Error("Unauthorized");
            }
            return result;
          })
          .then(result => result.json())
          .then(data => {
            this.categories = data
          });
      },
      getTransactions() {
        console.log("getting transactions")
        this.loading = true;
        let mm = String(this.selected_month + 1).padStart(2, "0")
        let yyyy = String(this.selected_year)
        var query = '?month=' + (mm) + '&year=' + (yyyy);
        get('transactions' + query)
          .then(result => {
            if (result.status == 401) {
              localStorage.removeItem('token');
              this.$router.push('/login');
              throw new Error("Unauthorized");
            }
            return result;
          })
          .then(result => result.json())
          .then(result => result.Items)
          .then(data => data = this.sortTransactions(data))
          .then(remoteRowData => {
            let expenses = []
            let incomes = []
            let savings = []
            let deleted = []
            let credit_card_payments = []

            remoteRowData?.forEach((transaction) => {
              if ("v3_deleted" in transaction && transaction.v3_deleted) {
                deleted.push(transaction);
              }
              else if ("v3_type" in transaction) {
                if (transaction['v3_type'] == 'investment') {
                  savings.push(transaction);
                }
                else if (transaction['v3_type'].includes('expense')) {
                  expenses.push(transaction);
                }
                else if (transaction['v3_type'].includes('income')) {
                  //transaction.v3_amount = transaction.v3_amount * -1;
                  incomes.push(transaction);
                }
                else if (transaction['v3_type'].includes('cc_payment')) {
                  credit_card_payments.push(transaction);
                } 
                else {
                  console.log("unknown transaction type")
                  console.log(transaction)
                }

              }
              else {
                console.log("v3_type not set in transaction")
                console.log(transaction)
              }
            });

            console.log("deleted transactions:")
            console.log(deleted)
            this.expenses = expenses;
            this.incomes = incomes;
            this.savings = savings;
            this.credit_card_payments = credit_card_payments;
            this.calculateCategoryTotals();
            this.loading = false;

          })
          .catch(err => { alert(err); this.loading = false; });
          
      },
      sortTransactions (rowData) {
        if (rowData) {
          rowData.sort(function(a, b) {
            let a_date = Date.parse(a.authorized_date ? a.authorized_date : a.date);
            let b_date = Date.parse(b.authorized_date ? b.authorized_date : b.date);
            if (a_date < b_date) {
              return -1;
            }
            else if (a_date > b_date) {
              return 1
            }
            return 0;
          });
        }
        return rowData
      },
      onFileChanged(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          let lines = data.split(/\r\n|\n/);
          lines = lines.slice(1);
          let transactions = [];
          lines.forEach((line) => {
            const fields = line.split(',');
            if (fields.length > 1) {
              let date = new Date(fields[0].replace(/"/g, ''));
              let amount = parseFloat(fields[2].replace(/"/g, '')) * -1;
              let transaction = {
                v3_date: date.toISOString().split('T')[0],
                v3_name: fields[1].replace(/"/g, ''),
                v3_amount: amount,
                v3_type: amount < 0 ? 'expense' : 'cc_payment',
                v3_category: 'other',
                v3_account_name: 'Penfed - Power Cash Rewards',
              };
              console.log(transaction);
              transactions.push(transaction);
            }
          });
          post('transactions', transactions)
            .then(result => {
              if (result.status == 401) {
                localStorage.removeItem('token');
                this.$router.push('/login');
                throw new Error("Unauthorized");
              }
              return result;
            })
            .then(result => result.json())
            .then(() => {
              this.getTransactions();
            });
        };
        reader.readAsText(file);
      },
      
    },
    created() {
      checkLogin();
      this.getCategories();
      this.getTransactions();
      window.addEventListener('keydown', (e) => {

          if (e.key == 'Escape') {
            if (this.selected_transaction) {
              // sort the object by key
              const keyValuePairs = Object.entries(this.selected_transaction);
              keyValuePairs.sort((a, b) => a[0].localeCompare(b[0]));
              this.selected_transaction = Object.fromEntries(keyValuePairs);

              this.showDetailView();
            }
            e.stopPropagation();
          }

        });
    },
    watch: {
      expenses() {
        this.calculateCategoryTotals();
      },
      month(newmonth) {
        this.selected_month = newmonth - 1;
        this.getTransactions();
      },
      year(newyear) {
        this.selected_year = newyear;
        this.getTransactions();
      },
    }
  });
  </script>

<template>
  <div>
    <MDBModal
      id="exampleModal"
      tabindex="-1"
      labelledby="detailViewModal"
      v-model="show_modal"
      size="lg"
    >
      <MDBModalHeader>
        <MDBModalTitle id="detailViewModalLabel">{{selected_transaction.v3_name}}</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <JsonViewer
          :value=selected_transaction
          :expand-depth=1
          copyable
          expanded
        >
        </JsonViewer>
      </MDBModalBody>
    </MDBModal>
    <MonthNav
      :selected_month="selected_month" 
      :selected_year="selected_year" 
    />

    <CategoryGrid
      :data="categories"
      :loading="loading"
      @category-selected="setSelectedCategory"
      />
    <BudgetItemGrid
      heading="spending"
      type="expense"
      :data="expenses"
      :loading="loading"
      :expense_categories="categories"
      :selected_category="selected_category"
      :year="selected_year"
      :month="selected_month"
      @transaction-selected="setSelectedTransaction"
      @transaction-edited="transactionEdited"
      @transaction-deleted="transactionEdited"
      @transaction-added="transactionEdited"
    />
    <BudgetItemGrid
      heading="income"
      type="income"
      :data="incomes"
      :loading="loading"
      :selected_category="selected_category"
      :year="selected_year"
      :month="selected_month"
      @transaction-selected="setSelectedTransaction"
      @transaction-deleted="transactionEdited"
      @transaction-edited="transactionEdited"
      @transaction-added="transactionEdited"
    />
    <BudgetItemGrid
      heading="saving"
      type="investment"
      :data="savings"
      :loading="loading"
      :selected_category="selected_category"
      :year="selected_year"
      :month="selected_month"
      @transaction-selected="setSelectedTransaction"
      @transaction-deleted="transactionEdited"
      @transaction-edited="transactionEdited"
      @transaction-added="transactionEdited"
    />
    <BudgetItemGrid
      heading="credit card payments"
      type="cc_payment"
      :data="credit_card_payments"
      :loading="loading"
      :selected_category="selected_category"
      :year="selected_year"
      :month="selected_month"
      @transaction-selected="setSelectedTransaction"
      @transaction-deleted="transactionEdited"
      @transaction-edited="transactionEdited"
      @transaction-added="transactionEdited"
    />
    <div>
      <input
        type="file"
        @change="this.onFileChanged($event)"
        accept="text/csv"
        capture
      />
    </div>
  </div>
</template>
