<template>
    <div class="budget-item-grid">
        <h4 class="budget-item-grid-title">{{heading}}</h4>
        <div style="display: flex; flex-direction: column; height: 100%">
            <div style="width: 100%; flex: 1 1 auto;">
                <ag-grid-vue
                    :style="{ height }"
                    class="ag-theme-alpine"
                    :columnDefs="columnDefs"
                    :rowData="griddata"
                    :rowSelection="rowSelection"
                    @gridReady="onGridReady"
                    @cellValueChanged="handleCellValueChanged"
                    :overlayLoadingTemplate="overlayLoadingTemplate"
                    @selection-changed="onSelectionChanged"
                    :loading="loading"
                    @rowDataUpdated="onRowDataUpdated"
                    :pinnedBottomRowData="[pinnedBottomRowData]"
                >
                </ag-grid-vue> 
            </div>
            </div>
        </div>
</template>

<script>
    import "ag-grid-community/styles/ag-grid.css";
    import "ag-grid-community/styles/ag-theme-alpine.css";
    import { AgGridVue } from "ag-grid-vue3";
    import { ref } from "vue";
    import { patch, post } from '../common/api';

let rowHeight = 42;



export default {
    
    name: "BudgetItemGrid",
    props: {
        expense_categories: Array,
        data: Array,
        loading: Boolean,
        heading: String,
        selected_category: Object,
        month: Number,
        year: Number,
        type: String,
    },
    components: {
      AgGridVue,
    },
    emits: ['transaction-selected', 'transaction-edited', 'transaction-deleted', 'transaction-added'],
    data() {
        return {
            griddata: [],
            gridApi: ref(null),
            height: "200px",    
            overlayLoadingTemplate: null,
            shiftDown: null,
            deleteDown: null,
            columnDefs: this.getColumnDefs(),
            rowSelection: {
                mode: 'multiRow',
                checkboxes: false,
                headerCheckbox: false,
                enableClickSelection: true,
            },
            pinnedBottomRowData: {},
        }
    },
    methods: {
        getName(params) {
            return params.data.v3_name ? params.data.v3_name : params.data.name
        },
        formatName(params) {
            return this.isEmptyPinnedCell(params)
                ? this.createPinnedCellPlaceholder(params)
                : params.value;
        },
        formatAmount(params) {
            return this.isEmptyPinnedCell(params)
                ? this.createPinnedCellPlaceholder(params)
                : '$' + Number(params.data.v3_amount).toFixed(2)
        },
        formatCategory(params) {
            return this.isEmptyPinnedCell(params)
                ? this.createPinnedCellPlaceholder(params)
                : this.expense_categories?.find(x => x.name === params.value)?.display_name;
        },
        formatDate(params) {
            return this.isEmptyPinnedCell(params)
                ? this.createPinnedCellPlaceholder(params)
                : params.data.v3_date?.slice(-2, params.data.v3_date.length);
        },
        setDate(params) {
            if (!params.newValue) {
                return false;
            }
            let day = params.newValue
            if (day < 10) {
                day = '0' + day
            }
            params.data.v3_date = this.year + '-' + (this.month + 1) + '-' + day;
            return true;
        },
        isEmptyPinnedCell({ node, value }) {
            return node && (node.rowPinned === 'bottom' && (value == null || value === undefined || value === ''));
        },
        createPinnedCellPlaceholder({ colDef }) {
            return colDef.headerName.toLowerCase() + '...';
        },
        getColumnDefs() {
            return [
                { 
                    colId: 'v3_date',
                    field: 'v3_date',
                    headerName: 'Day',
                    valueFormatter: this.formatDate,
                    valueGetter: (params) => params.data.v3_date, 
                    valueSetter: this.setDate,
                    sortingOrder: ["desc", "asc"], 
                    sortable: true, 
                    filter: true,
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                },
                { 
                    colId: 'v3_name',
                    field: 'v3_name',
                    headerName: "Name",
                    valueGetter: this.getName,
                    valueFormatter: this.formatName,
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 3, 
                    editable: true,
                },
                { 
                    colId: 'v3_amount',
                    field: 'v3_amount',
                    headerName: 'Amount',
                    valueFormatter: this.formatAmount, 
                    sortable: true, 
                    cellDataType: 'number',
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                },
                { 
                    colId: 'v3_category',
                    field: 'v3_category', 
                    headerName: 'Category',
                    sortable: true, 
                    filter: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { 
                        values: this.expense_categories?.map(x => x.name),
                    },
                    valueFormatter: this.formatCategory,
                    valueGetter: (params) => params.data.v3_category,
                    flex: 2, 
                    editable: true, 
                },
                { 
                    colId: 'v3_account_name',
                    field: 'v3_account_name',
                    headerName: 'Account',
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: true,
                    valueFormatter: this.formatName,
                }
            ]
        },
        onSelectionChanged() {
            const selectedRows = this.gridApi.getSelectedRows();
            var selected_transaction =
                selectedRows.length === 1 ? selectedRows[0] : null;
            this.$emit('transaction-selected', selected_transaction);
        },
        onRowDataUpdated() {
            this.calculateHeightAndTotal();
        },
        setInputRow(newData) {
            this.gridApi?.setGridOption('pinnedBottomRowData', [newData]);
        },
        handleCellValueChanged(params) {
            console.log(params)
            if (params.node.rowPinned === 'bottom') {
                if (this.isPinnedRowDataCompleted(params)) {
                    console.log("pinned row data completed")
                    console.log(params.data)
                    params.data.year_month = params.data.v3_date.slice(0, 7);
                    params.data.v3_type = this.type;
                    this.griddata.push(params.data);
                    // save data
                    post('transactions', [params.data])
                        .then(result => {
                            if (result.status == 401) {
                                localStorage.removeItem('token');
                                this.$router.push('/login');
                                throw new Error("Unauthorized");
                            }
                            return result;
                        })
                        .then(result => {
                            if (!result.ok) {
                                throw new Error("Failed to update, check console");
                            }
                            return result;
                        })
                        .then(result => result.json()[0])
                        .then(result => {
                            this.griddata = this.griddata.map(x => typeof x['date_transaction_id'] == 'undefined' ? result : x);
                            this.$emit('transaction-added', result);
                        })
                        .catch(err => { alert("Failed to update, check console"); console.log(err); });

                    //reset pinned row
                    this.pinnedBottomRowData = {};
                }
            }
            else {
                console.log(params.oldValue)
                console.log(params.newValue)
                params.node.data.v3_amount = Number(params.node.data.v3_amount);

            
                console.log("emitting transaction-edited");
                console.log(params.node.data)
                this.$emit('transaction-edited', params.node.data);

                patch('transactions', params.node.data)
                    .then(result => {
                        if (result.status == 401) {
                            localStorage.removeItem('token');
                            this.$router.push('/login');
                            throw new Error("Unauthorized");
                        }
                        return result;
                    })
                    .then(result => {
                        if (!result.ok) {
                            throw new Error("Failed to update, check console");
                        }
                        return result;
                    })
                    .then(() => {
                        this.calculateHeightAndTotal();
                    })
                    .catch(err => { alert("Failed to update, check console"); console.log(err); });
            }
        },
        isPinnedRowDataCompleted(params) {
            if (params.rowPinned !== 'bottom') return;
            return this.columnDefs.every((def) => this.pinnedBottomRowData[def.colId]);
        },
        deleteTransaction(transaction) {            
            patch('transactions', { date_transaction_id: transaction['date_transaction_id'], v3_deleted: true })
                .then(result => {
                    if (result.status == 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                        throw new Error("Unauthorized");
                    }
                    return result;
                })
                .then(result => {
                    if (!result.ok) {
                        throw new Error("Failed to update, check console");
                    }
                    return result;
                })
                .then(data => {
                    console.log("emitting transaction-deleted")
                    this.$emit('transaction-deleted', data);
                })
                .catch(err => { alert("Failed to delete, check console"); console.log(err) });
        },
        onGridReady(params) {
            this.gridApi = params.api;
        },
        generatePinnedBottomData() {

            // generate a row-data with null values
            let result = {};
            if (!this.gridApi) {
                return result;
            }
            this.gridApi.getColumns().forEach(item => {
                result[item.colId] = null;
            });
            return this.calculatePinnedBottomData(result);
        },
        calculatePinnedBottomData(target) {
            //**list of columns fo aggregation**
            let columnsWithAggregation = ['v3_amount'];
            columnsWithAggregation.forEach(element => {
                target[element] = 0;
                this.gridApi.forEachNodeAfterFilter((rowNode) => {
                    if (rowNode.data[element]) {
                        target[element] += rowNode.data[element];
                    }
                });
            })
            return target;
        },
        calculateHeightAndTotal() {
            this.height = (rowHeight * (this.griddata.length + 3) + 10).toString() + "px";
            let pinnedBottomData = this.generatePinnedBottomData();
            this.gridApi?.setGridOption('pinnedTopRowData', [pinnedBottomData]);
        },
    },
    watch: {
        data(newData) {
            this.griddata = newData.sort();
        },
        expense_categories() {
            this.columnDefs = this.getColumnDefs();
        }
    },
    updated() {
        if (this.selected_category) {
            this.gridApi.deselectAll();
            this.gridApi.forEachNode((node) => {
                node.data.v3_category == this.selected_category.name ? node.setSelected(true) : null
        });
        } else {
            this.gridApi.deselectAll();
        }

    },
    created() {
        this.overlayLoadingTemplate =
            '<span class="ag-overlay-loading-center">Getting transactions...</span>';
        
        window.addEventListener('keydown', (e) => {

            if (e.key == 'Delete') {
                this.deleteDown = true;
            }
            else if (e.key == 'Shift') {
                this.shiftDown = true;
            }

            if (this.deleteDown && this.shiftDown && this.selected_transaction) {
                e.preventDefault();
                e.stopPropagation();
                this.deleteTransaction(this.selected_transaction)
            }

        });

        window.addEventListener('keyup', (e) => {

            if (e.key == 'Delete') {
                this.deleteDown = false;
            }
            else if (e.key == 'Shift') {
                this.shiftDown = false;
            }
            
        });
    }

}
</script>

<style scoped>
    .budget-item-grid {
        margin-bottom: 40px;
        margin-top: 10px;   
    }
    .budget-item-grid-title {
        margin-left: 50px;
    }
</style>