<template>
    <footer class="app-footer">
        <a href="/logout">logout</a>
        <p>&copy; 2023 Walter Company. All rights reserved.</p>
    </footer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ApFooter',
  data() {
    return {
      //
    }
  }
})
</script>

<style scoped>
.app-footer {
    background-color: #f8f9fa;
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    box-shadow: 0 50vh 0 50vh #f8f9fa;
}

.app-footer p {
    margin: 0;
    color: #6c757d;
}
</style>