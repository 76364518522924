import { API_ROOT, API_TOKEN } from '../config';

const request = (endpoint, method, body) => {
    
    const request_data = {
        method: method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'x-api-key': API_TOKEN
        }),
        mode: 'cors',
        body: JSON.stringify(body)
    };

    if (localStorage.getItem('token')) {
        request_data.headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }

    return fetch(API_ROOT + '/' + endpoint, request_data);
}

export const get = (endpoint) => {
    return request(endpoint, 'GET');
}

export const post = (endpoint, body) => {
    return request(endpoint, 'POST', body);
}

export const put = (endpoint, body) => {
    return request(endpoint, 'PUT', body);
}

export const patch = (endpoint, body) => {
    return request(endpoint, 'PATCH', body);
}